.jyfooter-container {
    background-color: #171C61;
    height          : 180px;
    padding-left    : 70px;
    padding-right   : 70px;
    display         : flex;
    align-items     : center;
}

.jyfooter-info-container {
    color : aliceblue;
    height: 100px;
}

.jyfooter-logo-container{
    position        : absolute;
    float           : right;
    right           : 70px;
}