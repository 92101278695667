.jyheader-container {
    background-color: #171C61;
    height          : 72px;
    padding-left    : 70px;
    padding-right   : 70px;
    display: flex;
    align-items: center;
}

.jyheader-logo-container {
    /* width: 200px;
    height: 50px;     */
    /* background-image: url(../../images/footer_logo.svg); */
}

.jyheader-menu-container {
    background-image: none;
    position        : absolute;
    float           : right;
    right           : 70px;
}